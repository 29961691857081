:root {
  --font-family: "Roboto", sans-serif;
  --font-size: 1.5rem;
  --font-weight: 300;
  --line-height: 1.6;
  --letter-spacing: 0.0075em;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: white;
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
}

.background-container {
  width: 100%;
  height: 100%;
  background-image: url("images/background-large.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media screen and (max-width: 768px) {
  .background-container {
    background-image: url("images/background-small.jpg");
  }
}

.brand-logo {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.brand-name {
  font-size: 2rem;
}

.brand-logo img {
  object-fit: contain;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.nav-link {
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
  margin: 0 0.5rem;
}

.App-main {
  font-size: calc(8px + 2vmin);
  margin: 6rem;
}

/* About page */

.section-big {
  padding: 140px 0;
}

.card-style {
  background-color: transparent;
  border: none;
  margin-top: 4rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 40px;
}

.about-info {
  font-size: 1.2rem;
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
}

.character-img {
  width: 60%;
  height: 60%;
  object-fit: contain;
  transform: scaleX(-1);
  border-radius: 70%;
}

@media screen and (max-width: 768px) {
  .character-img {
    width: 60%;
    height: 60%;
    object-fit: contain;
    transform: scaleX(-1);
    border-radius: 70%;
  }
}

/* Project section */
.hero-box {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  overflow-y: auto;
}
